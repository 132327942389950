import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import Job from '../components/Job'
import jobslist from '../jobslist'

const CareersScreen = () => {
    return (
        <>
            <Container>
            <h1>Latest Jobs:</h1>
                <Row>
                    {jobslist.map(job => (
                        <Col key={job._id} xs={6} sm={6} md={6} lg={4} xl={4} xxl={4} className='p-3 rounded'>
                            <Job job={job} />
                        </Col>
                    ))}
                </Row>
            </Container>
        </>
    )
}

export default CareersScreen