import React from 'react'
import {
  MDBFooter,
  MDBContainer,
  MDBCol,
  MDBRow
} from 'mdb-react-ui-kit';

const Footer = () => {
  return (
    <MDBFooter className='footer' color='white' bgColor='dark'>
      <MDBContainer className='p-4'>
        <section className='mb-4 text-center'>
          <p>
            "From Earth to space, our valves keep the pace!"
          </p>
        </section>

        <section className=''>
          <MDBRow>
            <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
              <h5 className='text-uppercase text-white pb-3'>Links</h5>

              <ul className='list-unstyled mb-0 lh-lg'>
                <li>
                  <a href='https://martintesting.com' target='blank' style={{ color: 'yellow' }}>
                    Check out our Testing Lab!
                  </a>
                </li>
                <li>
                  <a href='http://autoflowproducts.com/' target='blank' style={{ color: 'yellow' }}>
                    Autoflow Products
                  </a>
                </li>
              </ul>
            </MDBCol>

            <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
              <h5 className='text-uppercase text-white text-underline pb-3'>Contact Us!</h5>

              <ul className='list-unstyled mb-0 lh-lg'>
                <li className='d-flex justify-content-start align-items-start'>
                  <i className="fas fa-home me-3" style={{ lineHeight: '24px' }}></i>
                  <p className='text-white text-start' style={{ lineHeight: '24px' }}>
                    1751 Aviation Blvd, Suite 100<br />Lincoln, CA 95648 U.S.A.
                  </p>

                </li>
                <li className='d-flex justify-content-start align-items-start'>
                  <i className="fas fa-envelope me-3" style={{ lineHeight: '24px' }}></i>
                  <p className='text-white text-start'>
                    INFO@PFC.IO
                  </p>
                </li>
                <li className='d-flex justify-content-start align-items-start'>
                  <i className="fas fa-phone me-3" style={{ lineHeight: '24px' }}></i>
                  <p className='text-white text-start'>
                    +1 (916) 626-3029
                  </p>
                </li>
              </ul>
            </MDBCol>

            <MDBCol lg='6' md='6' className='mb-4 mb-md-0'>
              {/* <div className='col-lg-6 col-md-12 mb-4 mb-md-0 mx-auto'> */}
              <div className='col-lg-12 col-md-12 mb-4 mb-md-0 mx-auto'>
                <div className='mapouter'>
                  <div className='gmap_canvas'>
                    <iframe title='googleMap' width="100%" height="300px" id="gmap_canvas"
                      src="https://maps.google.com/maps?q=1751%20aviation%20blvd&t=&z=13&ie=UTF8&iwloc=&output=embed"
                      frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
                    </iframe>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </MDBCol>
          </MDBRow>
        </section>
      </MDBContainer>

      <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        © 2022 Copyright:
        <a className='text-white p-3' href='https://precisionfluidcontrols.com/'>
          Precision Fluid Controls, Inc.
        </a>
      </div>
    </MDBFooter>
  )
}

export default Footer