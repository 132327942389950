import React, { Component, useState } from 'react';
import { Card } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import TextareaAutosize from 'react-textarea-autosize';
import DatePicker from 'react-date-picker';

const Section1 = (workflow, isReadOnly) => {
    const [startDate, changeStartDate] = useState(new Date(workflow.workflowitem.startDate));
    const [closeoutDate, changeCloseoutDate] = useState(new Date(workflow.workflowitem.closeoutDate));
    
    const form = {
        originator: workflow.workflowitem.originator,
        epop: workflow.workflowitem.epop
    }



    var originator = workflow.workflowitem.originator;
    function changeOrig(e) {
        form.originator = e.target.value;
        console.log(form);
    }


    var promoteR = workflow.workflowitem.setPromoteR;
    function setPromoteR() {
        promoteR = !promoteR;
    };


    function setEpop(e) {
        form.epop = e.target.value;
    }

    // submitSection = () => {
    // fetch('http://10.0.9.56:8080/create', {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({
    //         name: form.epop,
    //         status: "Engineering Configuration Management",
    //     }),
    // }).then(window.location.reload())
    //     .catch((err) => console.log('error'))
    // }

    function handleSubmit(e) {
        e.preventDefault();
        this.submitSection();
    };

    function setHold(e) {
        console.log("To be held...");
    }

    function setReject(e) {
        console.log("To be Rejected...");
    }

    return (
        <>
            <Card className='rounded'>
                <Card.Body>
                    <Card.Text as='div'>
                        <form onSubmit={handleSubmit}>
                            <fieldset>
                                <Table striped bordered hover>
                                    <tbody>
                                        <tr>
                                            <td>Start Date:</td>
                                            <td><DatePicker required
                                                value={startDate}
                                                onChange={changeStartDate}
                                            /></td>
                                            <td>Closeout Date:</td>
                                            <td><DatePicker required
                                                value={closeoutDate}
                                                onChange={changeCloseoutDate}
                                            /></td>
                                        </tr>
                                        <tr>
                                            <td>Originator:</td>
                                            <td><input type="text" defaultValue={originator} onChange={changeOrig} /></td>
                                            <td>Initial Project Number:</td>
                                            <td><input required type="text" defaultValue={workflow.workflowitem.initialProjectNumber} /></td>
                                        </tr>
                                        <tr>
                                            <td>Part Number:</td>
                                            <td><input required type="text" defaultValue={workflow.workflowitem.partNumber} /></td>
                                            <td>Supercedes Part #:</td>
                                            <td><input type="text" defaultValue={workflow.workflowitem.supercedesPart} /></td>
                                        </tr>
                                        <tr>
                                            <td>EPO-P#:</td>
                                            <td><input type="text" defaultValue={workflow.workflowitem.epop} onChange={setEpop} /></td>
                                            <td>State Changed to PromoteR:</td>
                                            <td>
                                                <input style={{ transform: 'scale(1.7)' }}
                                                    type="checkbox"
                                                    value='2'
                                                    checked={workflow.workflowitem.promoteR}
                                                    onChange={setPromoteR}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Description:</td>
                                            <td colSpan={3}><TextareaAutosize className='w-100' defaultValue={workflow.workflowitem.description} /></td>
                                        </tr>
                                    </tbody>
                                </Table>
                                {isReadOnly ? (
                                    <div className='d-flex justify-content-around'>
                                        <button className="btn btn-success" type="submit">Submit</button>
                                        <button className="btn btn-warning" type='button' onClick={setHold}>Hold</button>
                                        <button className="btn btn-danger" type='button' onClick={setReject}>Reject</button>
                                    </div>
                                ) : (<></>)}
                            </fieldset>
                        </form>
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    )
}
// }

export default Section1