const jobslist = [
    {
        _id: '1',
        name: 'I.T. Support Specialist',
        description: 'IT Support Specialists are responsible for analyzing, troubleshooting and evaluating technology issues. This role may support internal clients, helping to keep employees productive, or external customers of a company, helping them via the telephone or webchat. Some specialists are tasked with giving instructions to customers on how to use business-specific programs or working with other technicians to resolve problems. Other specialists may be responsible for answering simpler questions from nonbusiness customers, like reestablishing internet connections or troubleshooting household IT products like Wi-Fi routers.',
        department: 'Information Technology',
    },
    {
        _id: '2',
        name: 'Machinist',
        description: 'We are looking for a skilled Machinist to operate a variety of machines to perform assigned tasks. You will produce or repair precision parts using both manual and automated equipment and accurate measurements. A great machinist needs to possess manual dexterity and an eye for detail. They must also have knowledge of mathematics and mechanics as well as respect for safety precautions The goal is to ensure effective production operations and optimize procedures.',
        department: 'Manufacturing',
    },
    {
        _id: '3',
        name: 'Engineer',
        description: 'We are looking for a talented manufacturing engineer to oversee and improve manufacturing processes. The manufacturing engineer\'s responsibilities include identifying areas for improvement, maintaining high levels of manufacturing and product quality, designing new products and processes, as well as ensuring cost-efficiency and conformance with regulatory standards.',
        department: 'Engineering',
    },
];

export default jobslist