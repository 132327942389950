import React, { useState } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { useNavigate } from "react-router-dom";
import { Container } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import images from '../images';
import Dropdown from 'react-bootstrap/Dropdown';

const Header = () => {
    var navigate = useNavigate();
    const [authenticated, setAuthenticated] = useState(sessionStorage.getItem('token'));
    const [user] = useState(sessionStorage.getItem('user') || null);

    const logout = () => {
        sessionStorage.removeItem('token');
        setAuthenticated(null);
        navigate('/login');
        window.location.reload(); 
    }

    return (
        <>
            <header style={{ minWidth: '100%' }}>
                <Navbar collapseOnSelect expand="lg" variant='dark' className='text-uppercase' style={{ backgroundColor: 'black' }}>
                    <Container>
                        <LinkContainer to='/'>
                            <Navbar.Brand>
                                <img src={images[0]} alt="PFC" height='150px' />
                            </Navbar.Brand>
                        </LinkContainer>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                            </Nav>
                            <Nav>
                                <LinkContainer to='/'>
                                    <Nav.Link>Home</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to='/about'>
                                    <Nav.Link>About</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to='/contactus'>
                                    <Nav.Link>Contact Us!</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to="/careers">
                                    <Nav.Link>Careers</Nav.Link>
                                </LinkContainer>
                                {(!authenticated) && <LinkContainer to='/login'><Nav.Link>Employee Login</Nav.Link></LinkContainer>}
                                {(authenticated) &&
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            Dashboard
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item>Greetings {user}</Dropdown.Item>
                                            <LinkContainer to='/dashboard'>
                                                <Dropdown.Item>Dashboard</Dropdown.Item>
                                            </LinkContainer>
                                            <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        </>
    )
}

export default Header