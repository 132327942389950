import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import DatePicker from 'react-date-picker';
import EngWf001 from './workflow/eng-wf-001/EngWf001';

const NewWorkflow = () => {

    // useEffect(() => {
    //     fetch(`http://10.0.1.12:8080/createWorkflow`)
    //         .then((response) => {
    //             if (!response.ok) {
    //                 setError(`Response error: ${response.status}`);
    //                 throw new Error(
    //                     `This is an HTTP error: The status is ${response.status}`
    //                 );
    //             }
    //             return response.json();
    //         })
    //         .then((actualData) => {
    //             setWorkflowData(actualData);
    //             setError(null);
    //         })
    //         .catch((err) => {
    //             setError('Errors: ' + err.message);
    //         })
    //         .finally(() => {
    //             setLoading(false);
    //         });
    // }, []);

    const [typeOfWorkflow, setTypeOfWorkflow] = useState('eng-wf-001');
    const [startDate, setStartDate] = useState(new Date());
    const [closeoutDate, setcloseoutDate] = useState(new Date());
    const isReadOnly = false;



    const form = {
        startDate: startDate,
        closeoutDate: closeoutDate,
    }


    // submitSection = () => {
    // fetch('http://10.0.9.56:8080/create', {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({
    //         name: form.epop,
    //         status: "Engineering Configuration Management",
    //     }),
    // }).then(window.location.reload())
    //     .catch((err) => console.log('error'))
    // }

    function testing(e) {
        setTypeOfWorkflow(e.target.value);
    }

    function handleSubmit(e) {
        e.preventDefault();
        this.submitSection();
    };

    function setHold(e) {
        console.log("To be held...");
    }

    function setReject(e) {
        console.log("To be Rejected...");
    }

    return (
        <>
            <h2><Link to={`/login`}>Back...</Link></h2>
            <Card className='rounded'>
                <Card.Body>
                    <Card.Text as='div'>
                        <Table striped bordered hover>
                            <tbody>
                                <tr>
                                    <td colSpan={2}>Type of Workflow:</td>
                                    <td colSpan={2}>
                                        <select className='mb-3' defaultValue={typeOfWorkflow} onChange={testing}>
                                            <option value="eng-wf-001">Part: Promote from Concept to Released State</option>
                                            <option value="eng-wf-002">Assembly: Promote from Concept to Released State</option>
                                            <option value="eng-wf-003">Part: Promote from Concept to Experimental State</option>
                                            <option value="eng-wf-014">Configuration Item:  Promote to Reference State</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        {(typeOfWorkflow === 'eng-wf-001') && <EngWf001 />}
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    )
}

export default NewWorkflow