import React, { useEffect, useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import { Routes, Route } from 'react-router';
import { Link, Navigate } from 'react-router-dom';
import WorkflowItems from '../components/WorkflowItems';
import WorkflowitemScreen from './WorkflowItemScreen';
import NewWorkflow from '../components/NewWorkflow';

const WorkflowScreen = () => {
    const [workflowdata, setWorkflowData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [authenticated, setAuthenticated] = useState(sessionStorage.getItem("token"));

    useEffect(() => {
        fetch(`http://10.0.1.12:8080/workflowlist`)
            .then((response) => {
                if (!response.ok) {
                    setError(`Response error: ${response.status}`);
                    throw new Error(
                        `This is an HTTP error: The status is ${response.status}`
                    );
                }
                return response.json();
            })
            .then((actualData) => {
                setWorkflowData(actualData);
                setError(null);
            })
            .catch((err) => {
                setError('Errors: ' + err.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (!authenticated) {
        return (
            <Navigate replace to="/login" />
        )
    }
    if (authenticated) {
        return (
            <>
                <h1>Dashboard</h1>

                {loading && <p>A moment please...</p>}

                {error && (
                    <p>{`There is a problem fetching the post data - ${error}`}</p>
                )}

                {workflowdata && (
                    (<>
                            <Container>
                                <Row>
                                    <Routes>
                                        <Route key={'createWF'} path='/createnewworkflow' element={<NewWorkflow />} />
                                    </Routes>
                                    <Link key={'newworkflow'} to={'/createnewworkflow'} state={{ workflowdata: workflowdata }}>
                                        <h3 className="btn btn-success">+ Create New Workflow</h3>
                                    </Link>
                                </Row>
                                <Routes>
                                    {workflowdata.map((item) => (
                                        <Route
                                            key={item.id}
                                            path={`/workflowitem/${item.id}`}
                                            element={<WorkflowitemScreen />}
                                        />
                                    ))}
                                </Routes>
                                <Row>
                                    <Col>
                                        <Row>
                                            {workflowdata.map((item) => (
                                                <Link key={item.id} to={`/workflowitem/${item.id}`} state={{ workflow: item }}>
                                                    <WorkflowItems key={item.id} workflowitem={item} />
                                                </Link>
                                            ))}
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </>))
                }
            </>
        )
    }
}

export default WorkflowScreen