import React, { useEffect, useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import { Routes, Route } from 'react-router';
import { Link, Navigate } from 'react-router-dom';
import WorkflowItems from '../components/WorkflowItems';
import WorkflowitemScreen from './WorkflowItemScreen';
import NewWorkflow from '../components/NewWorkflow';
import WorkflowScreen from './WorkflowScreen';
import CareersManagement from '../components/CareersManagement';

const EmployeeDashboardScreen = () => {
    const [workflowdata, setWorkflowData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [authenticated, setAuthenticated] = useState(sessionStorage.getItem("token"));

    // useEffect(() => {
    //     fetch(`http://10.0.1.12:8080/workflowlist`)
    //         .then((response) => {
    //             if (!response.ok) {
    //                 setError(`Response error: ${response.status}`);
    //                 throw new Error(
    //                     `This is an HTTP error: The status is ${response.status}`
    //                 );
    //             }
    //             return response.json();
    //         })
    //         .then((actualData) => {
    //             setWorkflowData(actualData);
    //             setError(null);
    //         })
    //         .catch((err) => {
    //             setError('Errors: ' + err.message);
    //         })
    //         .finally(() => {
    //             setLoading(false);
    //         });
    // }, []);

    if (!authenticated) {
        return (
            <Navigate replace to="/login" />
        )
    }
    if (authenticated) {
        return (
            <>
                <Routes>
                    <Route key={'workflowscreen'} path='/workflows' element={<WorkflowScreen />} />
                    <Route key={'careersManagement'} path='/careersmanagement' element={<CareersManagement />} />
                </Routes>
                <Container>
                    <Row>
                        <Link key={'toWorkflows'} to={'/workflows'}>
                            <h3 className="btn btn-success">Go To Workflows</h3>
                        </Link>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Link key={'tocareersManagement'} to={'/careersmanagement'}>
                                    <h3 className="btn btn-success">Go To Manage Career Opportunities</h3>
                                </Link>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <h2>More to come...</h2>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default EmployeeDashboardScreen