import React from 'react'
import images from '../images/index';
import { Container, Row, Image } from 'react-bootstrap';

const AboutScreen = () => {
  return (
    <>
      <Container>
        <Row>
          <h2 className=''>What we do:</h2>
          <li>Precision Fluid Controls, Inc. designs, manufactures and tests valves and pressure controls for
            aerospace propulsion, flight and ground applications. With a large portfolio of products, we can
            quickly provide solutions for many pressure control requirements, or create new products based on our
            customers' specifications. Products include main engine valves, vent/relief valves, pressure reducing
            regulators, check valves and high pressure components. PFC was established in 2004.</li>
        </Row>
        <Image src={images[27]} rounded />
        <Image src={images[28]} rounded />
        <Image src={images[29]} rounded />
        <Image src={images[30]} rounded />
      </Container>
      <div className="container">
        <ul>
          <div className='row'>
            {/* <img src={images[27]} className="col-md-6 p-2" alt="Example 1" /> */}
            {/* <img src={images[28]} className="col-md-6 p-2" alt="Example 2" /> */}
            {/* <img src={images[29]} className="col-md-6 p-2" alt="Example 3" /> */}
            {/* <img src={images[30]} className="col-md-6 p-2" alt="Example 4" /> */}
          </div>

          <li>
            <h2 className="text-uppercase fw-bold mb-4">Who we do it for:</h2> PFC has designed
            and
            built products for many major commercial launch companies as well as
            ground facilities at Kennedy Space Center, Stennis Space Center, Marshall Space Flight Center,
            Arnold
            AFB,
            Edwards AFB, Vandenberg AFB, NASA and others.
          </li>

          <li>

            <h2 className="text-uppercase fw-bold mb-4 pt-3">Facility:</h2> The facility is equiped
            with
            four 5-axis CNC Live Tool Lathes, a CNC Mill, a Manual Mill, a Speed
            Lathe, a Browne & Sharp Coordinate Measuring Machine, a Nitrogen Dewar, and a Class 10,000 Soft Wall
            Clean
            Room.
          </li>
          <div className='row'>
            <img src={images[6]} className="col-md-6 p-2" alt="Assembly" />
            <img src={images[7]} className="col-md-6 p-2" alt="Assembly" />
            <img src={images[8]} className="col-md-6 p-2" alt="Assembly" />
            <img src={images[9]} className="col-md-6 p-2" alt="Assembly" />
            <img src={images[10]} className="col-md-6 p-2" alt="Assembly" />
            <img src={images[11]} className="col-md-6 p-2" alt="Assembly" />
            <img src={images[5]} className="col-md-6 p-2" alt="Assembly" />
          </div>
        </ul>
      </div>
    </>
  )
}

export default AboutScreen