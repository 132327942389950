import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

// Components
import Header from './components/Header';
import Footer from './components/Footer';

// Screens
import HomeScreen from './screens/HomeScreen';
import CareerScreen from './screens/CareersScreen';
import ContactUsScreen from './screens/ContactUsScreen';
import AboutScreen from './screens/AboutScreen';
import EmployeeDashboardScreen from './screens/EmployeeDashboardScreen';
import WorkflowitemScreen from './screens/WorkflowItemScreen';
import Login from './screens/LoginScreen';
import PageNotFound from './screens/PageNotFound';
import NewWorkflow from './components/NewWorkflow';
import WorkflowScreen from './screens/WorkflowScreen';
import CareersManagement from './components/CareersManagement';

function App() {
  return (
    <Router>
      <Header></Header>
      <main>
        <Container>
          <Routes>
            <Route path='/careers' element={<CareerScreen />} />
            <Route path='/dashboard/*' element={<EmployeeDashboardScreen />} />
            <Route path='/login/' element={<Login />} />
            <Route path='/contactus' element={<ContactUsScreen />} />
            <Route path='/about' element={<AboutScreen />} />
            <Route path='/' element={<HomeScreen />} exact />
            <Route path='/workflowitem/*' element={<WorkflowitemScreen />} />
            <Route path='/workflows/*' element={<WorkflowScreen />} />
            <Route path='/createnewworkflow' element={<NewWorkflow />} />
            <Route path='/careersManagement' element={<CareersManagement />} />
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </Container>
      </main>
      <Footer></Footer>
    </Router>
  );
}

export default App;
