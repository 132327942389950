import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import DatePicker from 'react-date-picker';
import TextareaAutosize from 'react-textarea-autosize';

const EngWf001 = () => {

  // useEffect(() => {
  //     fetch(`http://10.0.1.12:8080/createWorkflow`)
  //         .then((response) => {
  //             if (!response.ok) {
  //                 setError(`Response error: ${response.status}`);
  //                 throw new Error(
  //                     `This is an HTTP error: The status is ${response.status}`
  //                 );
  //             }
  //             return response.json();
  //         })
  //         .then((actualData) => {
  //             setWorkflowData(actualData);
  //             setError(null);
  //         })
  //         .catch((err) => {
  //             setError('Errors: ' + err.message);
  //         })
  //         .finally(() => {
  //             setLoading(false);
  //         });
  // }, []);

  const [startDate, setStartDate] = useState(new Date());
  const [closeoutDate, setcloseoutDate] = useState(new Date());
  const [originator] = useState(sessionStorage.getItem('user'));
  const [peerEngineer, setPeerEngineer] = useState();

  // const location = useLocation();



  const form = {
    startDate: startDate,
    closeoutDate: closeoutDate,
  }


  // submitSection = () => {
  // fetch('http://10.0.9.56:8080/create', {
  //     method: 'POST',
  //     headers: {
  //         'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //         name: form.epop,
  //         status: "Engineering Configuration Management",
  //     }),
  // }).then(window.location.reload())
  //     .catch((err) => console.log('error'))
  // }

  function handleSubmit(e) {
    e.preventDefault();
    this.submitSection();
  };

  function changePeerEngineer(e) {
    setPeerEngineer(e.target.value);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <fieldset>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td>Start Date:</td>
                <td><DatePicker required
                  value={form.startDate}
                  onChange={setStartDate}
                /></td>
                <td>Closeout Date:</td>
                <td><DatePicker required
                  value={form.closeoutDate}
                  onChange={setcloseoutDate}
                /></td>
              </tr>
              <tr>
                <td>Originator:</td>
                <td><input readOnly value={originator} /></td>
                <td>Initial Project Number:</td>
                <td><input required type="text" defaultValue={''} /></td>
              </tr>
              {/* <tr>
                                            <td>Part Number:</td>
                                            <td><input required type="text" defaultValue={''} /></td>
                                            <td>Supercedes Part #:</td>
                                            <td><input type="text" defaultValue={''} /></td>
                                        </tr> */}
              {/* <tr>
                                            <td>EPO-P#:</td>
                                            <td><input type="text" defaultValue={''} onChange={{}} /></td>
                                            <td>State Changed to PromoteR:</td>
                                            <td>
                                                <input style={{ transform: 'scale(1.7)' }}
                                                    type="checkbox"
                                                    value='2'
                                                    checked={''}
                                                    onChange={{}}
                                                />
                                            </td>
                                        </tr> */}
              <tr>
                <td>Description:</td>
                <td colSpan={3}><TextareaAutosize className='w-100' defaultValue={''} /></td>
              </tr>
              <tr>
                <td colSpan={2}>Peer Check Engineer:</td>
                <td colSpan={2}>
                  <select className='mb-3' defaultValue={peerEngineer} onChange={changePeerEngineer}>
                    <option value="eng-wf1">Engineer 1</option>
                    <option value="eng-wf2">Engineer 2</option>
                    <option value="eng-wf3">Engineer 3</option>
                    <option value="eng-wf4">Engineer 4</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>Analysis Engineer:</td>
                <td colSpan={2}>
                  <select className='mb-3' defaultValue={peerEngineer} onChange={changePeerEngineer}>
                    <option value="eng-wf1">Engineer 1</option>
                    <option value="eng-wf2">Engineer 2</option>
                    <option value="eng-wf3">Engineer 3</option>
                    <option value="eng-wf4">Engineer 4</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>Manufacturing Engineer:</td>
                <td colSpan={2}>
                  <select className='mb-3' defaultValue={peerEngineer} onChange={changePeerEngineer}>
                    <option value="eng-wf1">Engineer 1</option>
                    <option value="eng-wf2">Engineer 2</option>
                    <option value="eng-wf3">Engineer 3</option>
                    <option value="eng-wf4">Engineer 4</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>Inspection Check:</td>
                <td colSpan={2}>
                  <select className='mb-3' defaultValue={peerEngineer} onChange={changePeerEngineer}>
                    <option value="eng-wf1">Engineer 1</option>
                    <option value="eng-wf2">Engineer 2</option>
                    <option value="eng-wf3">Engineer 3</option>
                    <option value="eng-wf4">Engineer 4</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>Cognizant Engineer:</td>
                <td colSpan={2}>
                  <select className='mb-3' defaultValue={peerEngineer} onChange={changePeerEngineer}>
                    <option value="eng-wf1">Engineer 1</option>
                    <option value="eng-wf2">Engineer 2</option>
                    <option value="eng-wf3">Engineer 3</option>
                    <option value="eng-wf4">Engineer 4</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className='d-flex justify-content-around'>
            <button className="btn btn-success" type="submit">Submit</button>
          </div>
        </fieldset>
      </form>
    </>
  )
}

export default EngWf001