import React from 'react'
import images from '../images/index';
import { Container, Row, Col, Image } from 'react-bootstrap'

const HomeScreen = () => {

    return (
        <>
            <div className='container'>
                <ul>
                    <div className='row'><h1 className='col-12'>Welcome to Precision Fluid Controls</h1></div>
                    <div className='row'><li className='pt-3'>Precision Fluid Controls, Inc. designs, manufactures and tests components for launch vehicles and ground support
                        applications—providing many types of valves including throttling valves, ball valves, check and relief valves,
                        vent and relief valves, pressure reducing, both manual and dome loaded, regulators, sleeve valves, butterfly
                        valves, solenoid valves plus many others.
                    </li></div>
                    <div className='row'><li className='pt-3'>Our customers include most major commercial launch vehicle companies. Ground support customers include NASA
                        facilities such as Stennis Space Center, Marshall Space Flight Center and Kennedy Space Center. Air Force
                        customers include Arnold Air Force Base and Edwards Air Force Base.
                    </li></div>
                    <div className='row'><li className='pt-3'>PFC is currently located in a 15,000 square foot facility in Rocklin, California. The company was founded by
                        Peggy Stevens and Tom Ritchart in 2004. To control product quality and operating expenses, PFC machines the vast
                        majority of all component parts in their machine shop using state-of-the-art Mazak live tool lathes and Mazak
                        mills. In support of the U.S. economy, PFC components which are not made in house are manufactured and sourced
                        in the United States.</li></div>
                </ul>
                <Container>
                    <Row>
                        <Col>
                            <Image src={images[24]} />
                            <Image src={images[14]} />
                        </Col>
                        <Col>
                            <Image src={images[13]} />
                            <Image src={images[17]} />
                        </Col>
                    </Row>
                </Container>
                <div className='row'>
                    <img className='col-md-6 p-2' width={"100%"} src={images[24]} alt={`img 24`} />
                    <img className='col-md-6 p-2' width={"100%"} src={images[14]} alt={`img 14`} />
                    <img className='col-md-6 p-2' src={images[13]} alt={`img 13`} />
                    <img className='col-md-6 p-2' src={images[17]} alt={`img 17`} />
                    <img className='col-md-6 p-2' src={images[21]} alt={`img 21`} />
                    <img className='col-md-6 p-2' src={images[35]} alt={`img 35`} />
                    <img className='col-md-6 p-2' src={images[36]} alt={`img 36`} />
                </div>
            </div>
        </>
    )
}

export default HomeScreen